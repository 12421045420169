@charset "utf-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components { .btn-primary {
 @apply inline-block font-Lato font-light py-2 leading-6 px-10 border-darkgray rounded-xl text-white bg-darkgray hover:bg-darkgreen }
.btn-secondary { @apply font-Lato font-light py-2 leading-5 px-10 border border-black rounded-xl text-black bg-transparent hover:bg-black hover:text-white
}
.shadow-xxl { box-shadow: 13px 20px 68px rgba(0,0,0,0.1) }
.shadow-sxl { box-shadow: 10px 15px 38px rgba(0,0,0,0.1); }
.shadow-xs { box-shadow: 5px 5px 18px rgba(0,0,0,0.1); }
.green-gradient { background: #a0ca92; background: -moz-linear-gradient(top, #a0ca92 0%, #5c8e5c 100%); background: -webkit-linear-gradient(top, #a0ca92 0%, #5c8e5c 100%); background: linear-gradient(to bottom, #a0ca92 0%, #5c8e5c 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a0ca92', endColorstr='#5c8e5c', GradientType=0 );
}
.blue-gradient { background: #b4eae8; width: 50%; padding: 120px 60px 30px; text-align: center; background: #b4ebe8; background: -moz-linear-gradient(top, #b4ebe8 0%, #94a6cf 100%); background: -webkit-linear-gradient(top, #b4ebe8 0%, #94a6cf 100%); background: linear-gradient(to bottom, #b4ebe8 0%, #94a6cf 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4ebe8', endColorstr='#94a6cf', GradientType=0 );
}
 @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
.unit-radio { display: block; position: relative; margin-bottom: 12px; cursor: pointer; font-size: 22px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
.unit-radio input { position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0; }
.unit-radio .checkmark { position: relative; display: block; top: 0; left: 0; border: 2px solid #02abe7; border-radius: 20px; width: 22px; height: 22px; }
.unit-radio input:checked ~ .checkmark { background-color: transparent; }
.unit-radio .checkmark:after { content: ""; position: absolute; display: none; }
.unit-radio input:checked ~ .checkmark:after { display: block; }
.unit-radio .checkmark:after { top: 4px; left: 4px; width: 10px; height: 10px; border-radius: 50%; background: rgba(0,0,0,0.5); }
.unit-radio.big .checkmark { width: 44px; height: 44px; border-color: #dbe0e6; background-color: #dbe0e6; border-radius: 44px; -webkit-border-radius: 44px; -moz-border-radius: 44px; -ms-border-radius: 44px; -o-border-radius: 44px; }
.unit-radio.big .checkmark:after { opacity: 0 }
.unit-radio.big input:checked ~ .checkmark { background-color: #02abe7; border-color: #02abe7; }
.unit-checkbox { display: block; position: relative; margin-bottom: 12px; cursor: pointer; font-size: 22px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
.unit-checkbox input { position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0; }
.unit-checkbox .checkmark { position: relative; display: block; top: 0; left: 0; border: 2px solid #02abe7; border-radius: 4px; width: 22px; height: 22px; }
.unit-checkbox input:checked ~ .checkmark:after { display: block; }
.unit-checkbox .checkmark:after { content: ""; position: absolute; display: none; left: 6px; top: 2px; width: 7px; height: 12px; border: solid #02abe7; border-width: 0 2px 2px 0; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); }
.checkbox-button:hover .checkmark, .completed .checkmark { border-color: #ffffff; background-color: #ffffff; }
}